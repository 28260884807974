export default {
  menu: {
    pools: 'Pools',
    sewagePumps: 'Sewage pumps',
    chargePoint: 'Charge Points',
    companyManagement: 'Company',
    templates: 'Templates',
    admin: 'Administration',
    productCatalogue: 'Products',
    inviteCompany: 'Invite Company',
    logout: 'Logout',
  },
  deviceTypes: {
    POOL: 'Swimming Pool',
    SEWAGE_PUMP: 'Sewage Pump',
    CHARGE_POINT: 'Charge Point',
  },
  device: 'Thing',
  devices: 'Things',
  basicInformation: 'Basic Data',
  operatingValues: 'Operating Data',
  controls: 'Controls',
  language: 'Language',
  lang: {
    cs: 'Čeština',
    en: 'English',
    de: 'Deutsch',
    pl: 'Polski',
  },
  daysOfWeek: {
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday',
    SUNDAY: 'Sunday',
  },
  daysOfWeekShort: {
    MONDAY: 'Mo',
    TUESDAY: 'Tu',
    WEDNESDAY: 'We',
    THURSDAY: 'Th',
    FRIDAY: 'Fr',
    SATURDAY: 'Sa',
    SUNDAY: 'Su',
  },
  reset: 'Reset',
  save: 'Save',
  delete: 'Delete',
  cancel: 'Cancel',
  discard: 'Discard',
  back: 'Back',
  continue: 'Continue',
  confirm: 'Confirm',
  enter: 'Enter',
  accept: 'Accept',
  close: 'Close',
  submit: 'Submit',
  edit: 'Edit',
  unassign: 'Unassign',
  add: 'Add',
  remove: 'Remove',
  clear: 'Clear',
  manage: 'Manage',
  view: 'View',
  abort: 'Abort',
  ok: 'OK',
  hide: 'Hide',
  id: 'ID',
  email: 'Email',
  name: 'Name',
  title: 'Name',
  firstName: 'First Name',
  lastName: 'Last Name',
  contactName: 'Contact Person',
  contactEmail: 'Contact Email',
  contactPhone: 'Contact Phone',
  companyName: 'Company Name',
  phoneNumber: 'Phone',
  description: 'Description',
  enabled: 'Enabled',
  deactivated: 'Deactivated',
  contactInfo: 'Contact Information',
  address: 'Address',
  billingAddress: 'Billing Address',
  note: 'Note',
  set: 'Set',
  location: 'GPS Coordinates',
  map: 'Map',
  settings: 'Configuration',
  template: 'Template',
  deviceName: 'Thing name',
  status: 'Order Status',
  state: 'State',
  defaultTimeSchedule: 'Default Time Schedule',
  myDevices: 'My Things',
  product: 'Product',
  endUserProduct: 'End User Product',
  professionalUserProduct: 'Professional User Product',
  customer: 'Customer',
  company: 'Company',
  type: 'Type',
  companyAccess: 'Company Access',
  myAccess: 'My Access',
  password: {
    notSame: 'Passwords do not match.',
    notValid:
      'Password needs to contain at least 8 characters, 1 number, lowercase and uppercase characters and at least one special character.',
  },
  countryCodes: {
    al: 'Albania',
    ad: 'Andorra',
    am: 'Armenia',
    at: 'Austria',
    az: 'Azerbaijan',
    by: 'Belarus',
    be: 'Belgium',
    ba: 'Bosnia and Herzegovina',
    bg: 'Bulgaria',
    hr: 'Croatia',
    cy: 'Cyprus',
    cz: 'Czechia',
    dk: 'Denmark',
    ee: 'Estonia',
    fi: 'Finland',
    fr: 'France',
    ge: 'Georgia',
    de: 'Germany',
    gr: 'Greece',
    hu: 'Hungary',
    is: 'Iceland',
    ie: 'Ireland',
    it: 'Italy',
    kz: 'Kazakhstan',
    lv: 'Latvia',
    li: 'Liechtenstein',
    lt: 'Lithuania',
    lu: 'Luxembourg',
    mt: 'Malta',
    md: 'Moldova',
    mc: 'Monaco',
    me: 'Montenegro',
    nl: 'Netherlands',
    mk: 'North Macedonia',
    no: 'Norway',
    pl: 'Poland',
    pt: 'Portugal',
    ro: 'Romania',
    ru: 'Russia',
    sm: 'San Marino',
    rs: 'Serbia',
    sk: 'Slovakia',
    si: 'Slovenia',
    es: 'Spain',
    se: 'Sweden',
    ch: 'Switzerland',
    tr: 'Turkey',
    ua: 'Ukraine',
    gb: 'United Kingdom',
    va: 'Vatican City (Holy See)',
  },
  addressItems: {
    route: 'Installation Site',
    city: 'City',
    postalCode: 'ZIP Code',
    country: 'Country',
    lat: 'Latitude',
    lng: 'Longitude',
  },
  addressSpecification: 'Address Details',
  addressHint: 'Select an address or fill in manually.',
  yes: 'Yes',
  no: 'No',
  errors: {
    emailAlreadyExists:
      'An account with this email address already exists. Please log in using the method you used for registration.',
    invalidInvitation: 'Invitation has expired or is invalid.',
    userDeactivated:
      'Account has been deactivated. To reactivate your account please contact technical support.',
    somethingWentWrong: 'Something went wrong. Try again or contact technical support.',
  },
  newDeviceResetConfirmationModal: {
    heading: 'Reset Form',
    text: 'Are you sure you want to restore default values?',
  },
  companyInvite: {
    inviteCompany: 'Invite company',
    description: 'Enter email of the administrator of the company you want to invite.',
    submit: 'Send Invitation',
    invitationSuccess: 'Invitation successfully sent.',
    invitationError: 'Failed to send invitation.',
    sentInvitations: 'Sent Invitations',
    resendInvitation: 'Resend',
  },
  createCompanyModal: {
    headline: 'Create a new company',
    description: 'Enter the company name.',
    companyName: 'Company Name',
    successMessage: 'Company created successfully.',
    errorMessage: 'Failed to create company.',
    companyNameTakenError: 'Company name is taken. Please choose another name.',
  },
  formValidation: {
    required: 'Required field',
    notPhoneNumber: 'Enter a valid phone number incl. country code.',
    notEmail: 'Enter a valid email address.',
    notSameAsPassword: 'Passwords does not match.',
    tooLong: 'Maximum length exceeded.',
    max: 'Value must be lower than or equal to {max}',
    min: 'Value must be higher than or equal to {min}',
    integer: 'Value must be an integer.',
  },
  pageNotFound: 'Page not found.',
  insufficientPermissions: 'Insufficient permissions.',
  unsavedChanges: 'Unsaved changes. Are you sure discard changes?',
  on: 'On',
  off: 'Off',
  copiedToClipboard: 'Copied to clipboard.',
  copyToClipboardFailed: 'Copy to clipboard failed.',
  all: 'All',
  doFilter: 'Filter',
  schedule: 'Time Schedule',
  schedules: 'Time Schedules',
  hrs: 'hrs.',
  dataUpdateSuccess: 'Data successfully updated.',
  dataUpdateFailure: 'Failed to update data.',
  invalidInvitationModal: {
    header: 'Invalid invitation.',
    text: 'Invitation has expired or become invalid.',
  },
  acceptCompanyToCompanyInvitationModal: {
    header: 'Invitation to company',
    text: 'Your company, {childCompanyName}, has been invited to {parentCompanyName} company. By accepting this invitation, {childCompanyName} will become a service partner for {parentCompanyName}. Do you want to accept this invitation?',
  },
  acceptCompanyToCompanyInvitation: {
    success: 'Invitation successfully accepted.',
    failure: 'Failed accepting invitation.',
  },
  templateManagement: 'Template management',
  addTemplate: 'Add Template',
  emptyTemplate: 'Empty Template',
  dataTable: {
    itemsPerPageText: 'Rows per page:',
    allItemsText: 'All',
    of: 'of',
  },
  anonymous: 'Anonymous',
  adminConsole: 'Admin Console',
  adminConsoleMenu: {
    users: 'Users',
    devices: 'Things',
    productCatalogue: 'Product Catalogue',
    companies: 'Companies',
    serviceHints: 'Service hints',
    notificationDispatcher: 'Notifications',
    thingTypes: 'Thing Types',
  },
  productCatalogueMenu: {
    devices: 'Things',
    customers: 'Customers',
    endUserProducts: 'End User Products',
    professionalUserProducts: 'Professional user products',
  },
  addCustomer: 'Add Customer',
  addEndUserProduct: 'Add End User Product',
  addProfessionalUserProduct: 'Add Professional User Product',
  customerCreate: {
    success: 'Customer successfully created.',
    failure: 'Failed to create customer.',
  },
  customerUpdate: {
    success: 'Customer data successfully updated.',
    failure: 'Failed to edit customer data.',
  },
  customerDetail: 'Customer Detail',
  customerNotFound: 'Customer not found.',
  editCustomerData: 'Edit Customer Data',
  deviceType: 'Thing Category',
  eligibilities: 'Eligibilities',
  productCreate: {
    success: 'Product successfully created.',
    failure: 'Failed to create product.',
  },
  days: 'days',
  hours: 'hours',
  minutes: 'minutes',
  thingType: 'Thing Type',
  selectThingTypesHint: 'Select one or more Thing types.',
  endUserProductDetail: 'End user product detail',
  professionalUserProductDetail: 'Professional user product detail',
  productNotFound: 'Product not found.',
  defaultProductForType: 'Default product for {thingType}',
  defaultProduct: 'Default Product',
  systemProduct: 'System Product',
  editProduct: 'Edit Product',
  productUpdate: {
    success: 'Product successfully updated.',
    failure: 'Failed to update product.',
  },
  addDevice: 'Add Thing',
  assignDeviceToCustomer: 'Assign Thing to Customer',
  deviceAssignCustomerAndProduct: {
    success: 'Thing successfully assigned to customer.',
    failure: 'Failed to assign Thing.',
  },
  deviceRemoveCustomerAndProduct: {
    success: 'Thing successfully removed from customer.',
    failure: 'Failed to remove Thing.',
  },
  deviceUnassignConfirmation: {
    heading: 'Remove Thing from the customer',
    text: 'Are you sure you want to remove "{deviceName}" ({controller}) from the customer?',
  },
  changeProduct: 'Change Product',
  deviceChangeProduct: {
    success: 'Product changed successfully.',
    failure: 'Failed to change product.',
  },
  customerDeleteConfirmation: {
    heading: 'Delete Customer',
    text: 'Are you sure you want to delete customer {customerName} ({email})?',
  },
  deleteCustomer: {
    success: 'Customer deleted successfully.',
    failure: 'Failed to delete customer.',
  },
  transferDevices: 'Transfer Things to other customer',
  doNotTransferDevices: 'Do not transfer Things',
  productDeleteConfirmation: {
    heading: 'Delete Product',
    text: "Are you sure you want to delete product '{productName}'?",
  },
  deleteProduct: {
    success: 'Product deleted successfully.',
    failure: 'Failed to delete product.',
  },
  transferDevicesProduct: 'Transfer Things to other product',
  transferToDefaultProduct: 'Transfer Thing to default product',
  setAsDefaultProduct: 'Set product as default',
  adminProductCatalogueMenu: {
    companyEligibilities: 'Company Eligibilities',
    deviceTypeEligibilities: 'Thing Type Eligibilities',
    companyAllowedDeviceTypes: 'Allowed Thing types for companies',
    generalEligibilities: 'General Eligibilities',
  },
  companiesThingTypesUpdate: {
    success: 'Allowed Things types updated successfully.',
    failure: 'Failed to update of Thing types.',
  },
  allowed: 'Allowed',
  endUserDefaultValue: 'Default Value for End User',
  professionalUserDefaultValue: 'Default value for professional user',
  generalEligibility: 'General Eligibility',
  updateEligibility: 'Update Eligibility',
  eligibility: 'Eligibility',
  thingTypesEligibilitiesUpdate: {
    success: 'Eligibility updated successfully.',
    failure: 'Failed to update eligibility.',
  },
  companyAllowedEligibilitiesUpdate: {
    success: 'Allowed eligibilities for companies updated successfully.',
    failure: 'Failed to update allowed eligibilities for companies.',
  },
  addEligibility: 'Add Eligibility',
  deviceEligibilityCreate: {
    success: 'Eligibility created successfully.',
    failure: 'Failed to create eligibility.',
  },
  generalEligibilitiesUpdateDefaultValues: {
    success: 'Default values of general eligibilities updated successfully.',
    failure: 'Failed to update default values of general eligibilities.',
  },
  eligibilityDeleteConfirmation: {
    heading: 'Delete Eligibility',
    text: "Are you sure you want to delete eligibility '{eligibility}'?",
  },
  eligibilityDelete: {
    success: 'Eligibility deleted successfully.',
    failure: 'Failed to delete eligibility.',
  },
  addAccess: 'Add Access',
  addAccessConfirmation: {
    heading: 'Add Service Access',
    text: 'Are you sure you want to allow your company {childCompany} full service access to {parentCompany}?',
  },
  addCurrentCompanyAsServiceCompany: {
    success: 'Company added successfully.',
    failure: 'Failed to add company.',
  },
  removeAccess: 'Remove Access',
  removeAccessConfirmation: {
    heading: 'Remove Service Access',
    text: "Are you sure you want to remove your company's {childCompany} service access to {parentCompany}'s Things?",
  },
  removeCurrentCompanyAsServiceCompany: {
    success: 'Service access removed successfully.',
    failure: 'Failed to remove service access.',
  },
  addMyselfToCompanyConfirmation: {
    heading: 'Add Administrator Access to Company',
    text: 'Are you sure you want to grant yourself administrator access to company {companyName}?',
  },
  addMyselfToCompany: {
    success: 'Administrator access granted successfully.',
    failure: 'Failed to grant administrator access.',
  },
  removeMyselfFromCompanyConfirmation: {
    heading: 'Remove Access to Company',
    text: 'Are you sure you want to remove your access to company {companyName}?',
  },
  removeMyCompanyAccess: {
    success: 'Access removed successfully.',
    failure: 'Failed to remove access.',
  },
  invitationState: {
    accepted: 'ACCEPTED',
    expired: 'EXPIRED',
    pending: 'PENDING ACCEPTANCE',
  },
  createCompanyInvitationDeleteConfirmation: {
    heading: 'Delete Invitation',
    text: 'Are you sure you want to delete the invitation to {email}?',
  },
  createCompanyInvitationDelete: {
    success: 'Invitation successfully deleted.',
    failure: 'Failed to delete invitation.',
  },
  resendCooldownTooltip: 'Action will be available again in {remainingTime} seconds.',
  newElegibilityWarning:
    'New eligibility. This eligibility did not exist when the product was created and therefore has default value. This notification will disappear after product update.',
  invitationLanguage: 'Invitation Language',
  userAccessesTab: {
    deviceAdmin: 'Thing Administrator',
    guests: 'Guests',
    demoTokens: 'Demo Tokens',
    guest: 'Guest',
    activeDeviceAdminInvitation: 'Administrator invitation sent.',
    activeInvitation: 'Invitation sent',
    noDeviceAdmin: 'Thing currently does not have an administrator.',
    noGuests: 'No guests.',
    noTokens: 'No tokens.',
    inviteUser: 'Invite User',
    changeDeviceAdmin: 'Transfer Thing to a new administrator',
    changeDeviceAdminModal: {
      headline: 'Transfer Thing to a new administrator',
      text: 'This will immediately change your role to guest and send an invitation email to the new Thing administrator.',
      selectExistingUser: 'Select a new administrator from guest users.',
      enterEmail: 'Enter email address.',
    },
    confirmChangeDeviceAdminModal: {
      headline: 'Are you sure you want to transfer Thing to a new administrator?',
      text: 'Are you sure you want to transfer the administrator role for Thing {deviceName} to the user with email {invitationEmail}? This event will immediately change your role to guest and send an invitation email to the new Thing administrator.',
    },
    inviteUserModal: { headline: 'Invite New User' },
    confirmDeleteAccessModal: {
      headline: 'Delete User Access',
      text: 'Are you sure you want to remove access to {deviceName} for {email}?',
    },
    editModal: {
      text: 'When changing role from guest to administrator, the previous administrator will be changed to guest used. A Thing can only have one administrator.',
      role: 'Role',
      removeUsersAccess: 'Delete Access',
    },
    removeOwnAccessModal: {
      headline: 'Delete My Access',
      text: 'Do you want to transfer Thing administrator role to someone else?',
      deleteWithTransfer: 'Choose user',
      deleteWithoutTransfer: 'Delete Without Transfer',
      selectExistingUser: 'Select new administrator from guest users.',
      enterEmail: 'Enter email address',
    },
    confirmRemoveOwnAccessModal: {
      headline: 'Delete My Access',
      text: "Are you sure you want to delete your access to Thing '{deviceName}' while transferring administrator role to {invitationEmail}?",
    },
    removeWithoutTransferConfirmationModal: {
      headline: 'Delete my access without transfer',
      text: "Are you sure you want to delete your access to Thing, '{deviceName}', without transferring the administrator role to another user?",
    },
    addDemoTokenModal: {
      headline: 'Add Demo Token',
      submitBtnText: 'Add Token',
    },
    inviteUserAction: {
      success: 'User successfully invited.',
      failure: 'Failed to send user invitation.',
      alreadyInvited: 'User is already invited.',
      alreadyHasAccess: 'User already has access.',
    },
    transferDeviceAdminAction: {
      success: 'New Thing administrator successfully invited.',
      failure: 'Failed to transfer Thing to the new administrator.',
      alreadyDeviceAdmin: 'This user already is the Thing administrator.',
    },
    deleteUserAccessAction: {
      success: 'User access successfully deleted.',
      failure: 'Failed to delete user access.',
    },
    changeAccessTypeAction: {
      success: "User's role successfully updated.",
      failure: "Failed to update user's role.",
    },
    createDemoTokenAction: {
      success: 'Demo token successfully created.',
      failure: 'Failed to create demo token.',
    },
    wifiPassword: 'Wi-Fi Credentials',
  },
  troubleshooting: 'Troubleshooting',
  possibleCauses: 'Possible Causes',
  correctiveMeassures: 'Suggested Actions',
  changeServiceCompanyModal: {
    title: 'Change Service Company',
    description:
      'Enter email of the administrator of the company you want to invite. Invitation will be send to this email. Your service company will change only if company accepts your invitation.',
    activeInvitationDescription:
      'User\nThis Thing has active invitation for a new service company. Please delete the invitation to send invitation to a new company.',
    submit: 'Send Invitation',
    resendInvitation: 'Resend Invitation',
    deleteInvitation: 'Delete Invitation',
    activeInvitation: 'Active Invitation',
    invitationWillExpire: 'Invitation will expire',
    inviteServiceCompanyAction: {
      success: 'Service company successfully invited.',
      failure: 'Failed to send invitation to service company.',
      notAdminEmail: 'The email you entered does not belong to any company administrator.',
      alreadyServiceCompany: 'Company is already the service company for this Thing.',
    },
    resendInvitationSuccess: 'Invitation successfully resent.',
    deleteServiceCompanyInvitationAction: {
      success: 'Invitation successfully deleted.',
      failure: 'Failed to delete invitation.',
    },
  },
  serviceCompanyInvitationAcceptationModal: {
    title: "Invitation to the Thing's service access",
    step1: {
      title: 'Accept or Reject',
      accepted: 'Accepted',
      description:
        'Your company, {companyName}, has been invited by user {invitationFromEmail} to service Thing {deviceName}({controller}). To accept the invitation, click Continue and fill in required data.',
      rejectInvitation: 'Reject Invitation',
    },
    step2: {
      title: 'Select Customer',
      createNewCustomer: 'Create New Customer',
      submitBtn: 'Complete',
    },
    rejectInvitationAction: {
      success: 'Invitation successfully rejected.',
      failure: 'Failed to reject invitation.',
    },
    acceptInvitationAction: {
      success: 'Invitation successfully accepted.',
      failure: 'Failed to accept invitation.',
    },
    addProductCataloguePermissionAction: {
      success:
        'You have been assigned permission to the Product Catalogue to be able to finish this operation.',
      failure: 'Failed to accept invitation.',
    },
  },
  eventCode: 'Event Code',
  events: 'Events',
  detectionPoint: 'Detection Point',
  detectionPoints: 'Detection Points',
  coreCause: 'Core Cause',
  coreCauses: 'Core causes',
  correctiveAction: 'Corrective Action',
  correctiveActions: 'Corrective Actions',
  nameTakenError: 'Name is already taken.',
  adminEventCode: {
    severity: 'Severity',
    description: 'Description',
    disabled: 'Disabled',
    active: 'Active',
    detectionPoint: 'Detection Point',
    detectionMechanism: 'Detection Mechanism',
    terminationPoint: 'Termination Point',
    terminationMechanism: 'Termination Mechanism',
    actionUponDetection: 'Action upon Detection',
    availableCauses: 'Available Causes',
    assignedCauses: 'Assigned Causes',
  },
  adminEventCodes: {
    addEventCode: 'Add Event Code',
    createEventCodeAction: {
      success: 'Event code created successfully.',
      failure: 'Failed to create event code.',
    },
  },
  adminDetectionPoints: {
    addDetectionPoint: 'Add Detection Point',
    updateDetectionPoint: 'Update Detection Point',
    createDetectionPointAction: {
      success: 'Detection point created successfully.',
      failure: 'Failed to create detection point.',
    },
    deleteConfirmationModal: {
      heading: 'Delete Detection Point',
      text: "Are you sure you want to delete detection point '{detectionPoint}'?",
    },
    deleteDetectionPointAction: {
      success: 'Detection point deleted successfully.',
      failure: 'Failed to delete detection point.',
    },
    updateDetectionPointAction: {
      success: 'Detection point updated successfully.',
      failure: 'Failed to update detection point.',
    },
  },
  adminCoreCauses: {
    addCoreCause: 'Add Core Cause',
    createCoreCauseAction: {
      success: 'Core cause created successfully.',
      failure: 'Failed to create core cause.',
    },
  },
  adminCorrectiveActions: {
    addCorrectiveAction: 'Add Corrective Action',
    createCorrectiveActionAction: {
      success: 'Corrective action created successfully.',
      failure: 'Failed to create corrective action.',
    },
    deleteConfirmationModal: {
      heading: 'Delete Corrective Action',
      text: "Are you sure you want to delete corrective action '{correctiveAction}'?",
    },
    deleteCorrectiveActionAction: {
      success: 'Corrective action deleted successfully.',
      failure: 'Failed to delete corrective action.',
    },
    updateCorrectiveActionAction: {
      success: 'Corrective action updated successfully.',
      failure: 'Failed to update corrective action.',
    },
  },
  eventCodeDetail: {
    title: 'Event code detail',
    editAll: 'Edit all',
    addCoreCause: 'Add core cause',
    eventCodeNotFound: 'Event code not found.',
    assignCoreCauseAction: {
      success: 'Core cause assigned successfully.',
      failure: 'Failed to assign core cause.',
    },
    reorderCoreCausesAction: {
      success: 'Core causes reordered successfully.',
      failure: 'Failed to reorder core causes.',
    },
    deleteConfirmationModal: {
      heading: 'Delete event code',
      text: "Are you sure you want to delete event code '{eventCode}'?",
    },
    deleteEventCodeAction: {
      success: 'Event code deleted successfully.',
      failure: 'Failed to delete event code.',
    },
    unassignCoreCauseConfirmationModal: {
      heading: 'Unassign core cause',
      text: "Are you sure you want to unassign core cause '{coreCause}'?",
    },
    unassignCoreCauseAction: {
      success: 'Core cause unassigned successfully.',
      failure: 'Failed to unassign core cause.',
    },
    updateEventCodeModal: { heading: 'Update event code' },
    updateEventCodeAction: {
      success: 'Event code updated successfully.',
      failure: 'Failed to update event code.',
    },
  },
  eventCorrectiveActionDetail: {
    title: 'Corrective action detail',
    assignedToCoreCauses: 'Assigned to these core causes',
    correctiveActionNotFound: 'Corrective action not found.',
  },
  eventDetectionPointDetail: {
    title: 'Detection point detail',
    assignedToEventCodes: 'Assigned to these event codes',
    detectionPointNotFound: 'Detection point not found.',
  },
  eventCoreCauseDetail: {
    title: 'Core cause detail',
    addCorrectiveAction: 'Add corrective action',
    coreCauseNotFound: 'Core cause not found.',
    availableActions: 'Available actions',
    assignedActions: 'Assigned actions',
    assignedToEventCodes: 'Assigned to these event codes',
    assignCorrectiveActionAction: {
      success: 'Corrective action assigned successfully.',
      failure: 'Failed to assign corrective action.',
    },
    reorderCorrectiveActionsAction: {
      success: 'Corrective actions reordered successfully.',
      failure: 'Failed to reorder corrective actions.',
    },
    deleteConfirmationModal: {
      heading: 'Delete core cause',
      text: "Are you sure you want to delete core cause '{coreCause}'?",
    },
    deleteCoreCauseAction: {
      success: 'Core cause deleted successfully.',
      failure: 'Failed to delete core cause.',
    },
    unassignCorrectiveActionConfirmationModal: {
      heading: 'Unassign corrective action',
      text: "Are you sure you want to unassign corrective action '{correctiveAction}'?",
    },
    unassignCorrectiveActionAction: {
      success: 'Corrective action unassigned successfully.',
      failure: 'Failed to unassign corrective action.',
    },
    updateCoreCauseAction: {
      success: 'Core cause updated successfully.',
      failure: 'Failed to update core cause.',
    },
  },
  demoTokenTile: {
    token: 'Token',
    tokenValidUntil: 'Until',
    expired: 'Expired',
    neverExpires: 'Never expires',
  },
  demoTokenForm: {
    tokenExpiration: 'Token expiration',
    accessExpiration: 'Access expiration',
    neverExpires: 'Never expires',
    expirationDate: 'Expiration date',
  },
  updateDemoTokenModal: {
    headline: 'Edit demo token',
    updateDemoTokenAction: {
      success: 'Demo token updated successfully.',
      failure: 'Failed to update demo token',
    },
    deleteDemoTokenAction: {
      success: 'Demo token deleted successfully.',
      failure: 'Failed to delete demo token.',
    },
  },
  demoTokenDeleteConfirmation: {
    headline: 'Delete demo token',
    text: "Are you sure you want to delete demo token '{tokenName}'?",
  },
  noDevicesText: 'You do not have any Things. Start by adding a Thing in the menu.',
  demoQrPrintTitle: 'Your Poolmatix demo voucher',
  crowdin: {
    crowdin: 'Crowdin',
    uploadTranslations: 'Upload new sources to Crowdin',
    downloadTranslations: 'Download new translations from Crowdin',
    updateBase: 'Set English translations as the new base',
    confirmationDialog: 'Confirmation dialog',
    uploadConfirmationText:
      'Are you sure you want to upload new English source files to Crowdin from this environment?',
    downloadConfirmationText:
      'Are you sure you want to download new translations from Crowdin to this environment? Please be sure that there are no recent changes in this environment that have not been uploaded to Crowdin. If there are changes that have not been uploaded to Crowdin yet, you should do that first. Otherwise, you may lose the new changes.',
    updateBaseConfirmationText:
      'Are you sure you want to set new English translations in Crowdin as a new base (source) files in Crowdin?',
    crowdinActionSuccess: 'Action successfully finished.',
    crowdinActionFailure: 'Action failed.',
    crowdinActionPartialFailure: 'Action partially failed. See output.',
    downloadOnlyDatabase: 'Download strings to database only, do not create a pull request',
  },
  notificationDispatcher: {
    notificationDispatcher: 'Notification dispatcher',
    notificationFlow: 'Notification flow',
    delayConfiguration: 'Notification timing',
    event: 'Event',
    notificationClass: 'Notification class',
    firstNotification: 'First notification',
    secondNotification: 'Second notification',
  },
  notificationClasses: {
    NO_NOTIFICATION: '<span style="color: #6B6B6B">NO NOTIFICATION</span>',
    END_USER_ONLY: '<span style="color: #1F97FF">END USER</span> only',
    END_USER_THEN_PROFESSIONAL_USER:
      '<span style="color: #1F97FF">END USER</span> then <span style="color: #1867C1">PROFESSIONAL USER</span>',
    END_USER_AND_PROFESSIONAL_USER:
      '<span style="color: #1F97FF">END USER</span> and <span style="color: #1867C1">PROFESSIONAL USER</span>',
    PROFESSIONAL_USER_THEN_END_USER:
      '<span style="color: #1867C1">PROFESSIONAL USER</span> then <span style="color: #1F97FF">END USER</span>',
    PROFESSIONAL_USER_ONLY: '<span style="color: #1867C1">PROFESSIONAL USER</span> only',
  },
  defaultNotificationClass: 'Default notification class',
  updateNotificationClassDelays: {
    success: 'Notification timing successfully updated.',
    failure: 'Failed to update notification timing.',
  },
  serviceSequenceWizard: { deviceWentOffline: 'Thing has gone offline.' },
  abortServiceSequenceConfirmationModal: {
    heading: 'Abort service sequence',
    text: 'Are you sure you want to abort running service sequence?',
  },
  besgoValveBackwash: {
    title: 'Filter backwash - Besgo valve',
    idle: 'This operation will launch filter backwash sequence. Do you want to continue?',
    running: 'Performing filter backwash sequence.',
    completed: 'Filter backwash sequence completed. (Automatically disappears after 2 minutes).',
    failed: 'Filter backwash sequence failed.',
  },
  notResponding: 'Thing is not responding.',
  companyManagementMenu: {
    users: 'Users',
    serviceCompanies: 'Service companies',
    notifications: 'Notifications',
    settings: 'Settings',
  },
  companyDetail: {
    contactServicePartnerEmailTemplate: 'Contact service partner email template',
    emailAddressesForContactEmails: '"Contact service" destination email addresses',
    emailAddressesForEventNotifications: 'Event notifications destination email addresses',
    targetAddresses: 'Target addresses',
    targetAddressesHint: 'Select from options or add new by pressing Enter.',
    emailSubject: 'Email subject',
    emailBody: 'Email body',
    emailPreview: 'Email preview',
    emailVariables: 'Email fields',
    emailVariablesDescription:
      'You can use these fields in your email template. Fields will be filled out with real values.',
    invalidEmailAddresses: 'Enter valid email addresses.',
    restoreDefaults: 'Restore default email template',
    restoreDefaultsConfirmationModal: {
      heading: 'Restore default email template',
      text: 'Are you sure you want to restore the default email subject and body template?',
    },
    updateCompanyData: {
      success: 'Company data updated successfully.',
      failure: 'Failed to update company data.',
    },
    createNewEmail: 'Create',
  },
  myAccount: 'My account',
  changePassword: 'Change password',
  currentPassword: 'Current password',
  newPassword: 'New password',
  newPasswordAgain: 'Repeat new password',
  passwordChangeAction: {
    success: 'Password updated successfully.',
    failure: 'Failed to update password.',
    notAuthorized: 'Incorrect password.',
  },
  acceptDeviceAccessRequest: {
    headline: 'Accept Thing access request',
    success: 'You have successfully granted access to Thing "{deviceName}" to user {userEmail}.',
    invalidLink: 'Link is invalid.',
    requestNotFound: 'Link is invalid or expired.',
    failure: 'Failed to accept access to Thing.',
  },
  descriptions: 'Descriptions',
  overview: 'Overview',
  companyDefaultLanguage: 'Company language',
  serviceSequenceSendCommandFailure: 'Failed to send command to the Thing.',
  updateCompanyContactEmails: {
    success: 'Company contact emails updated successfully.',
    failure: 'Failed to update company contact emails.',
  },
  updateCompanyContactEmailTemplate: {
    success: 'Company contact email template updated successfully.',
    failure: 'Failed to update company contact email template.',
  },
  contactEmailTemplate: 'Contact email template',
  useDefaultConfiguration: 'Switch to settings',
  defaultConfiguration: 'Default',
  customConfiguration: 'Custom',
  companySwitchToDefaultSettings: {
    success: 'Successfully switched to default settings.',
    failure: 'Failed to switch to default settings.',
  },
  companySwitchToCustomSettings: {
    success: 'Successfully switched to custom settings.',
    failure: 'Failed to switch to custom settings.',
  },
  defaultValue: 'Default value',
  restoreDefaultNotificationDelaysConfirmationModal: {
    heading: 'Restore default notification timing',
    text: 'Are you sure you want to restore default notification timing settings? All current settings will be lost.',
  },
  restoreDefaultNotificationClassesConfirmationModal: {
    heading: 'Restore default notification classes',
    text: 'Are you sure you want to restore default notification classes? All current custom notification classes will be lost.',
  },
  updateNotificationClassForCompanyAndEventCode: {
    success: 'Notification class successfully updated.',
    failure: 'Failed to update notification class.',
  },
  timezone: 'Time zone',
  changeOperatingModeModal: { heading: 'Update operating mode' },
  changeOperatingModeConfirmationModal: {
    heading: 'Update operating mode',
    text: 'Are you sure you want to update operating mode?',
  },
  updateOperatingModeAction: {
    success: 'Operating mode successfully updated.',
    failure: 'Failed to update operating mode.',
  },
  predefinedSchedule: 'System Schedule',
  mySchedule: 'My Schedule',
  editingGlobalScheduleWarning:
    'Editing a system schedule will create your new copy of the schedule.',
  newTimeSchedule: {
    daily: 'New daily time schedule',
    weekly: 'New weekly time schedule',
  },
  timeScheduleName: 'Time Schedule Name',
  timeScheduleBaseTemplate: 'Default time schedule',
  editDailyTimeScheduleName: 'Edit daily time schedule name',
  editWeeklyTimeScheduleName: 'Edit Weekly Time Schedule Name',
  enterDailyTimeScheduleName: 'Enter Daily Time Schedule Name',
  enterWeeklyTimeScheduleName: 'Enter Weekly Time Schedule Name',
  weeklyTimeScheduleDeleteConfirmationHeading: 'Delete weekly schedule',
  weeklyTimeScheduleDeleteConfirmationText:
    "Are you sure you want to delete weekly schedule '{scheduleName}'?",
  newWeeklyTimeScheduleDeleteConfirmationHeading: 'Delete new weekly schedule',
  newWeeklyTimeScheduleDeleteConfirmationText:
    'Are you sure you want to delete this new weekly schedule?',
  dailyTimScheduleDeleteConfirmationHeading: 'Delete daily schedule',
  dailyTimScheduleDeleteConfirmationText:
    "Are you sure you want to delete daily schedule '{scheduleName}'?",
  newDailyTimScheduleDeleteConfirmationHeading: 'Delete new daily schedule',
  newDailyTimScheduleDeleteConfirmationText:
    'Are you sure you want to delete this new daily schedule?',
  addDailySchedule: 'Add daily time schedule',
  weeklyTimeSchedules: 'Weekly time schedules',
  dailyTimeSchedules: 'Daily time schedules',
  weeklyTimeSchedule: 'Weekly time schedule',
  dailyTimeSchedule: 'Daily time schedule',
  disabledDeleteTooltipForScheduleGlobal: 'Can not delete a system time schedule.',
  disabledDeleteTooltipForDailyScheduleUsed:
    'Cannot delete a used daily time schedule. This daily time schedule is used in a weekly time schedule. Please remove the daily schedule from all weekly time schedules first.',
  disabledDeleteTooltipForWeeklyActive:
    'Can not delete an active weekly time schedule. This weekly time schedule is currently active. Please activate another time schedule first.',
  disabledDeleteTooltipForWeeklyLast:
    'Can not delete the last remaining weekly time schedule. At least one weekly time schedule must exist. Please create a new weekly time schedule first.',
  disabledDeleteTooltipForWeeklyUsed:
    'Can not delete a used weekly time schedule. This weekly time schedule is used by some Things. Please make sure this weekly time schedule is not used by any Things first.',
  showMore: 'Show more',
  showLess: 'Show less',
  createServiceHistoryEntry: {
    success: 'Record successfully created.',
    failure: 'Failed to create record.',
  },
  customNotificationClasses: 'Custom notification classes',
  noCustomClasses: 'No custom notification classes',
  createCustomNotificationClass: 'Create custom notification class',
  createCustomNotificationClassAction: {
    success: 'Custom notification class successfully created.',
    failure: 'Failed to create custom notification class.',
  },
  deleteCustomNotificationClassAction: {
    success: 'Custom notification class successfully deleted.',
    failure: 'Failed to delete custom notification class.',
  },
  canNotDeleteUsedCustomNotificationClassTooltip: 'Cannot delete used custom notification class.',
  deleteCustomNotificationClassConfirmationModal: {
    heading: 'Delete custom notification class',
    text: "Are you sure you want to delete custom notification class '{className}'?",
  },
  newErrorNotification: 'New error notification',
  errorMessageClosed: 'Error notification closed',
  loadMore: 'Load more',
  backToTop: 'Back to top',
  backToDashboard: 'Back to Dashboard',
  backToProductsList: 'Back to Products',
  serviceHistoryEntryTypes: {
    USER_NOTE: 'User messages',
    EVENT: 'Events',
    OPERATION_COUNTER_CYCLE: 'New operating cycle',
  },
  filter: 'Filter',
  discardChanges: 'Discard changes',
  continueEditing: 'Continue editing',
  wifiCredentials: 'Wi-Fi Credentials',
  wifiCredentialsNotFound: 'Could not load Wi-Fi credentials. Please contact support.',
  show: 'Show',
  ssid: 'SSID',
  wifiQrCode: 'Scan QR code to connect from your phone.',
  iXsolveNotifications: 'iXsolve notifications',
  user: 'User',
  chargersApiKey: 'Charger API key',
  noChargerApiKey: 'You currently do not have any API key.',
  hasChargerApiKey:
    'Your API key is active. If you have lost the API key please generate a new one.',
  deleteApiKey: 'Delete API key',
  copyApiKeyWarning: 'Please save this new API key. You will not be able to display it again.',
  generateNewApiKey: 'Generate new API key',
  confirmGenerateNew: 'Delete old API key and generate a new one',
  generateNewApiKeyConfirmationModal: {
    heading: 'Generate new charger API key',
    text: 'Are you sure you want to generate a new API key? Your current API key will be deleted.',
  },
  deleteApiKeyConfirmationModal: {
    heading: 'Delete charger API key',
    text: 'Are you sure you want to delete your charger API key?',
  },
  generateNewApiKeyAction: {
    success: 'New charger API key successfully generated.',
    failure: 'Failed to generate new charger API key.',
  },
  deleteApiKeyAction: {
    success: 'Charger API key successfully deleted.',
    failure: 'Failed to delete charger API key.',
  },
  energy: 'Energy consumption',
  dateRangeChoice: {
    today: 'Today',
    yesterday: 'Yesterday',
    thisWeek: 'This week',
    lastWeek: 'Last week',
    thisMonth: 'This month',
    lastMonth: 'Last month',
    last30days: 'Last 30 days',
    last90days: 'Last 90 days',
    thisYear: 'This year',
    lastYear: 'Last year',
  },
  datesSelected: 'Selected',
  energyChartsNoData: 'Data not available for selected period.',
  hourCounter: 'Hour count',
  currentHourCounter: 'Current hour count',
  newHourCounter: 'New hour count',
  startCounter: 'Start count',
  currentStartCounter: 'Current start count',
  newStartCounter: 'New start count',
  cycleStartDate: 'Current cycle start',
  newCycleStart: 'New cycle start',
  newOperatingCycle: 'New operating cycle',
  startNewCycle: 'Start new cycle',
  equipmentId: 'Current equipment ID',
  newEquipmentId: 'New equipment ID',
  equipmentType: 'Equipment type',
  operationCounterNotePlaceholder: 'User note',
  operationCounterCycleCreateAction: {
    success: 'New operating cycle created.',
    failure: 'Failed to create new operating cycle.',
  },
  noOperationCounters: 'No available operation counters.',
  serviceOrderNumber: 'Order no.',
  assignee: 'Assigned to',
  createdBy: 'Created by',
  createdAt: 'Date / time',
  serviceOrderStatuses: {
    OPEN: 'Created',
    IN_PROGRESS: 'In progress',
    CLOSED: 'Closed',
  },
  serviceOrderText: 'Text',
  createServiceOrder: 'Create service order',
  createServiceOrderAction: {
    success: 'Service order successfully created.',
    failure: 'Failed to create service order.',
  },
  updateServiceOrderAction: {
    success: 'Service order successfully updated.',
    failure: 'Failed to update service order.',
  },
  deleteServiceOrderAction: {
    success: 'Service order successfully deleted.',
    failure: 'Failed to delete service order.',
  },
  serviceOrder: 'Service order',
  thingData: 'Thing data',
  serviceOrderDeleteConfirmation: {
    heading: 'Delete service order',
    text: 'Are you sure you want to delete service order {serviceOrderNumber}?',
  },
  emailNotifications: 'Email notifications',
  emailNotificationsAddFirst: 'Enter at least one email address to enable notifications.',
  smsNotifications: 'SMS notifications',
  smsNotificationsAddFirst: 'Enter at least one phone number to enable notifications.',
  pushNotifications: 'Push notifications',
  pushNotificationsText:
    'Push notifications are sent to all devices with iXmanager app that use this Thing.',
  deviceSetEventNotificationsAction: {
    success: 'Notifications settings successfully updated.',
    failure: 'Failed to update notification settings.',
  },
  energyReports: 'Energy reports',
  configurationJobInProgress: 'Settings update in progress',
  dataPropagationFailed: 'Settings update failed',
  configurationChanged: 'Changes require a restart of the Thing.',
  confirmDiscardChangesModal: {
    heading: 'Discard changes',
    text: 'Are you sure you want to discard all unsent changes to Thing? This will also discard any pending updates to the settings.',
  },
  deviceCancelConfigurationAction: {
    success: 'Settings update canceled successfully.',
    failure: 'Failed to cancel settings update.',
    inProgressError:
      'Settings update can no longer be canceled. Please wait until it is completed.',
  },
  mode: 'Mode',
  duplicateSchedule: 'Duplicate',
  duplicateScheduleTooltip: 'Create a new schedule from this schedule',
  repeat: 'Repeat',
  daily: 'Daily',
  activate: 'Activate',
  dailyScheduleNotUsedError:
    'A daily schedule needs to be used at least once in a weekly schedule.',
  everyDayHasToBeUsedError: 'Please set a daily schedule for each day of the week. Missing days:',
  weeklyScheduleEmptyError: 'A weekly schedule needs to contain 1 to 7 daily schedules.',
  scheduleInvalidNameError: 'Please enter a valid name for the schedule.',
  noChangeError: 'No changes have been made.',
  newSchedule: 'New schedule',
  nothingToSeeHereYet: 'No weekly schedules defined.',
  addFirstDailyScheduleMessage: 'Please add your first daily schedule.',
  saveWithWipModal: {
    heading: 'Unsaved interval',
    text: 'Some intervals have not been saved. Please go back and finish your changes or you choose to save the schedule with or without unsaved intervals.',
    saveWithWip: 'Save with unsaved intervals',
    saveWithoutWip: 'Save while skipping unsaved intervals',
  },
  editDailyTimeSchedule: 'Edit Daily Schedule',
  newWeeklySchedule: 'Add Weekly Schedule',
  newDailySchedule: 'New daily schedule',
  dailySchedules: 'Daily schedules',
  today: 'Today',
  activeWeeklySchedule: 'Active Weekly Schedule',
  saveDailyConfirmationModal: {
    heading: 'Save Changes',
    text: 'Daily schedule <b>{scheduleName}</b> that is used in these weekly schedules. Changes to the daily schedule will affect all these weekly schedules.',
  },
  duplicateSchedulePostfix: 'copy',
  new: 'new',
  selectAll: 'Select all',
  systemTimeScheduleEditValidationModal: {
    heading: 'System schedule cannot be edited.',
    info: 'You can create your own copy of the schedule and edit it.',
    duplicateAction: 'Create a Copy',
  },
  addProduct: {
    selectDevice: 'Select a Device',
    selectProduct: 'Select a Product',
    setTemplate: 'Set as template',
  },
  cancelSequence: 'Cancel Sequence',
  saveAndActivate: 'Save and activate',
  xeeloIntegration: 'Xeelo Integration',
  addXeeloIntegration: 'Add Xeelo Integration',
  addXeeloIntegrationConfirmation: {
    heading: 'Add Xeelo Integration',
    text: 'Are you sure to add Xeelo integration for company {companyName}?',
  },
  addXeeloIntegrationAction: {
    success: 'Xeelo integration successfully added.',
    failure: 'Failed to add Xeelo integration.',
  },
  paymentType: {
    ONE_OFF: 'One-Off',
    SUBSCRIPTION: 'Subscription',
  },
  serviceSequence: 'Service Sequence',
  chargingStart: 'Charging Start',
  duration: 'Duration',
  totalEnergy: 'Total Energy',
  chargingHistoryNoData: 'No data available.',
  export: 'Export Data',
  exportChargingHistory: 'Export Charging History',
  dateFrom: 'From',
  dateTo: 'To',
  chargingHistoryExportFailure: 'Failed to export charging history.',
  invalidDate: 'Invalid date.',
  chargingHistoryDateFromHint: 'Leave empty to start from the oldest available record.',
  chargingHistoryDateToHint: 'Default date is today.',
  errorInTimeScheduleConfiguration:
    'Unsupported time schedule configuration. Please contact your dealer.',
  errorInTimeScheduleConfigUpdate:
    'Unable to complete the system schedule change. The following devices have configurations that are incompatible with the new time schedule: {deviceList}."',
  eligibilityControl: {
    available: 'Available',
    notAvailable: 'Not Available',
    optional: 'Optional',
  },
  feature: 'Feature',
  eligibilityFilter: {
    all: 'All',
    oneOff: 'One-Off',
    subscription: 'Subscription-Based',
    serviceSequences: 'Service Sequences',
    premium: 'Premium',
  },
  creatBaseProduct: 'Create New Base Product',
  baseProduct: 'Base Product',
  selectProductTemplate: 'Select template',
  noTemplateYet: 'No Base Product available for this Thing type Family.',
  createProduct: 'Add New Base Product',
  availability: 'Availability',
  newProductCreate: {
    success: 'Product settings successfully saved.',
    failure: 'Failed to save product settings.',
  },
  newProductUpdate: {
    success: 'Product settings successfully updated.',
    failure: 'Failed to update product settings.',
  },
  addBaseProduct: 'New Base Product',
  editBaseProduct: 'Edit Base Product',
  productSettings: 'Product Settings',
  availabilityBaseProduct: 'Availability in Base Product',
  defaultAvailability: 'Default Availability',
  selectBaseProduct: 'Select Base Product',
  premium: 'Premium',
  newProductCatalogueMenu: {
    thingsPage: 'Things',
    baseProductsPage: 'Base Products',
  },
  changeProductModal: {
    title: 'Change Base Product',
    description: 'Changing Base Product will delete all Thing product settings. Continue?',
  },
  thingTypeFamily: 'Thing Type Family',
  premiumFeatureModalTitle: 'Enabling Premium Feature',
  premiumFeatureModalDesc:
    'You are enabling a premium feature. Additional charges may apply. Continue?',
  eligibilityControlTooltip:
    'This feature is always available. Default availability cannot be changed.',
  provisionType: 'Provision Type',
  updateThingType: 'Update Thing Type',
  thingTypeRelationUpdateSuccess: 'Relation update successful.',
  thingTypeRelationUpdateFail: 'Relation update failed.',
  tools: 'Tools',
  deleteWeeklyScheduleInfo: 'Weekly time schedule has been deleted.',
  deleteDailyScheduleInfo: 'Daily time schedule has been deleted.',
  duplicateWeeklyScheduleInfo: 'Weekly time schedule has been duplicated.',
  duplicateDailyScheduleInfo: 'Daily time schedule has been duplicated.',
  scheduleDuplicateFailed: 'Failed to duplicate time schedule.',
}
